<script>
export default {
  name: 'Postback',
  props: {
    value: {
      type: [Array],
      required: true,
    },
  },
  computed: {
    isArray() {
      return Array.isArray(this.value);
    },
  },
  methods: {
    getEnabledState(value) {
      return value ? 'Enabled' : 'Disabled';
    },
  },
};
</script>

<template lang="pug">
span
  ul(v-if="isArray")
    li(v-for="item in value" :key="item.url") {{ item.url }} - {{ item.type }} - {{ getEnabledState(item.enabled) }}
  span(v-else) {{ value }}
</template>
